.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1201;
  background: var(--color-background-paper);
  padding-top: env(safe-area-inset-top);
}

.main {
  background-color: var(--color-background-main);
  padding-left: 230px;
  padding-top: calc(var(--header-height) + env(safe-area-inset-top));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.sticky {
  position: sticky;
  top: calc(var(--header-height) + env(safe-area-inset-top));
  z-index: 1200;
}

.mainAnimated {
  transition: padding 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.mainNoSidebar {
  padding-left: 0;
}

.content {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.content main {
  padding: var(--space-3);
}

.sidebarTogglePosition {
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  /* mimics MUI drawer animation */
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.sidebarTogglePosition.sidebarOpen {
  transform: translateX(230px);
}

.sidebarToggle {
  height: 100vh;
  width: var(--space-1);
  background-color: var(--color-border-light);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
}

.sidebarToggle button {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -3px;
  transform: translateY(-50%);
  background-color: var(--color-border-light);
  clip-path: inset(0 -14px 0 0);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.sidebarToggle:hover,
.sidebarToggle:hover button {
  background-color: var(--color-background-light);
}

@media (max-width: 899.95px) {
  .main {
    padding-left: 0;
  }

  .smDrawerHidden {
    display: none;
  }
}

@media (max-width: 599.95px) {
  .main main {
    padding: var(--space-2);
  }
}
