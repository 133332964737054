@import url(/fonts/fonts.css);
/**
* ^^^^^^^
* Some IDEs show the fonts file above as not existing, but it is.
* If you modify the path to it make sure that the file is also loaded on the
* actual website.
* Fun fact: if this comment block is above the @import url(/fonts/fonts.css);
* nextjs's build will incorrectly place the @import in the middle of the file
* and the fonts won't load. More info on @import:
* https://developer.mozilla.org/en-US/docs/Web/CSS/@import#description
*/

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    DM Sans,
    sans-serif;
  background-color: var(--color-background-paper);
}

main {
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  font: inherit;
}

:focus-visible {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

* {
  box-sizing: border-box;
}

:root {
  --header-height: 52px;
  --footer-height: 67px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.illustration-main-fill {
  fill: var(--color-primary-main);
}

.illustration-light-fill {
  fill: var(--color-border-main);
}

.illustration-background-fill {
  fill: var(--color-logo-background);
}

.illustration-background-warning-fill {
  fill: var(--color-warning-background);
}

.illustration-background-paper-fill {
  fill: var(--color-background-paper);
}

.illustration-secondary-light-fill {
  fill: var(--color-secondary-light);
}

.illustration-text-primary-fill {
  fill: var(--color-text-primary);
}

/* Note: a fallback `stroke` property must be on the svg to work */
.illustration-main-stroke {
  stroke: var(--color-primary-main);
}

.illustration-light-stroke {
  stroke: var(--color-border-main);
}

.illustration-very-light-stroke {
  stroke: var(--color-border-light);
}

.illustration-background-stroke {
  stroke: var(--color-logo-background);
}

@media (max-width: 599.95px) {
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    background: var(--color-background-paper);
  }
}

body.beamerAnnouncementBarTopActive {
  padding-top: 0 !important;
}

#beamerLastPostTitle {
  left: 120px !important;
}

/* This file is generated from the MUI theme colors. Do not edit directly. */

:root {
  --color-text-primary: #121312;
  --color-text-secondary: #a1a3a7;
  --color-text-disabled: #dddee0;
  --color-primary-dark: #3c3c3c;
  --color-primary-main: #121312;
  --color-primary-light: #636669;
  --color-secondary-dark: #0fda6d;
  --color-secondary-main: #12ff80;
  --color-secondary-light: #b0ffc9;
  --color-secondary-background: #effff4;
  --color-border-main: #a1a3a7;
  --color-border-light: #dcdee0;
  --color-border-background: #f4f4f4;
  --color-error-dark: #ac2c3b;
  --color-error-main: #ff5f72;
  --color-error-light: #ffb4bd;
  --color-error-background: #ffe6ea;
  --color-success-dark: #028d4c;
  --color-success-main: #00b460;
  --color-success-light: #72f5b8;
  --color-success-background: #effaf1;
  --color-info-dark: #52bfdc;
  --color-info-main: #5fddff;
  --color-info-light: #b7f0ff;
  --color-info-background: #effcff;
  --color-warning-dark: #c04c32;
  --color-warning-main: #ff8061;
  --color-warning-light: #ffbc9f;
  --color-warning-background: #fff1e0;
  --color-background-default: #f4f4f4;
  --color-background-main: #f4f4f4;
  --color-background-paper: #ffffff;
  --color-background-light: #effff4;
  --color-backdrop-main: #636669;
  --color-logo-main: #121312;
  --color-logo-background: #eeeff0;
  --color-static-main: #121312;
  --space-1: 8px;
  --space-2: 16px;
  --space-3: 24px;
  --space-4: 32px;
  --space-5: 40px;
  --space-6: 48px;
  --space-7: 56px;
  --space-8: 64px;
  --space-9: 72px;
  --space-10: 80px;
  --space-11: 88px;
  --space-12: 96px;
}

[data-theme='dark'] {
  --color-text-primary: #ffffff;
  --color-text-secondary: #636669;
  --color-text-disabled: #636669;
  --color-primary-dark: #0cb259;
  --color-primary-main: #12ff80;
  --color-primary-light: #a1a3a7;
  --color-secondary-dark: #636669;
  --color-secondary-main: #ffffff;
  --color-secondary-light: #12ff80;
  --color-secondary-background: #1b2a22;
  --color-border-main: #636669;
  --color-border-light: #303033;
  --color-border-background: #121312;
  --color-error-dark: #ac2c3b;
  --color-error-main: #ff5f72;
  --color-error-light: #ffb4bd;
  --color-error-background: #2f2527;
  --color-success-dark: #028d4c;
  --color-success-main: #00b460;
  --color-success-light: #81c784;
  --color-success-background: #1f2920;
  --color-info-dark: #52bfdc;
  --color-info-main: #5fddff;
  --color-info-light: #b7f0ff;
  --color-info-background: #19252c;
  --color-warning-dark: #c04c32;
  --color-warning-main: #ff8061;
  --color-warning-light: #ffbc9f;
  --color-warning-background: #2f2318;
  --color-background-default: #121312;
  --color-background-main: #121312;
  --color-background-paper: #1c1c1c;
  --color-background-light: #1b2a22;
  --color-backdrop-main: #636669;
  --color-logo-main: #ffffff;
  --color-logo-background: #303033;
  --color-static-main: #121312;
}

/* The same as above for the brief moment before JS loads */
@media (prefers-color-scheme: dark) {
  :root:not([data-theme='light']) {
    --color-text-primary: #ffffff;
    --color-text-secondary: #636669;
    --color-text-disabled: #636669;
    --color-primary-dark: #0cb259;
    --color-primary-main: #12ff80;
    --color-primary-light: #a1a3a7;
    --color-secondary-dark: #636669;
    --color-secondary-main: #ffffff;
    --color-secondary-light: #12ff80;
    --color-secondary-background: #1b2a22;
    --color-border-main: #636669;
    --color-border-light: #303033;
    --color-border-background: #121312;
    --color-error-dark: #ac2c3b;
    --color-error-main: #ff5f72;
    --color-error-light: #ffb4bd;
    --color-error-background: #2f2527;
    --color-success-dark: #028d4c;
    --color-success-main: #00b460;
    --color-success-light: #81c784;
    --color-success-background: #1f2920;
    --color-info-dark: #52bfdc;
    --color-info-main: #5fddff;
    --color-info-light: #b7f0ff;
    --color-info-background: #19252c;
    --color-warning-dark: #c04c32;
    --color-warning-main: #ff8061;
    --color-warning-light: #ffbc9f;
    --color-warning-background: #2f2318;
    --color-background-default: #121312;
    --color-background-main: #121312;
    --color-background-paper: #1c1c1c;
    --color-background-light: #1b2a22;
    --color-backdrop-main: #636669;
    --color-logo-main: #ffffff;
    --color-logo-background: #303033;
    --color-static-main: #121312;
  }
}

:root {
  --w3o-background-color: var(--color-background-paper);
  --w3o-foreground-color: var(--color-border-light);
  --w3o-text-color: var(--color-text-primary);
  --w3o-border-color: var(--color-border-light);
  --w3o-action-color: var(--color-primary-main);
  --w3o-border-radius: 6px;
  --w3o-font-family: DM Sans, sans-serif;

  --onboard-border-radius-1: var(--w3o-border-radius);
  --onboard-border-radius-2: var(--w3o-border-radius);
  --onboard-border-radius-3: var(--w3o-border-radius);

  /* Palette */
  --onboard-white: var(--color-background-paper);
  --onboard-black: var(--color-text-primary);

  --onboard-primary-1: var(--color-secondary-main);
  --onboard-primary-100: var(--color-secondary-background);
  --onboard-primary-200: var(--color-primary-light);
  --onboard-primary-300: var(--color-primary-light);
  --onboard-primary-400: var(--color-primary-light);
  --onboard-primary-500: var(--color-primary-main);
  --onboard-primary-600: var(--color-primary-main);
  --onboard-primary-700: var(--color-secondary-main);

  --onboard-gray-100: var(--color-border-light);
  --onboard-gray-200: var(--color-border-main);
  --onboard-gray-300: var(--color-primary-light);
  --onboard-gray-400: var(--color-primary-main);
  --onboard-gray-500: var(--color-primary-main);
  --onboard-gray-600: var(--color-border-main);
  --onboard-gray-700: var(--color-text-primary);

  --onboard-success-100: var(--color-secondary-background);
  --onboard-success-600: var(--color-secondary-light);
  --onboard-success-700: var(--color-success-dark);

  --onboard-danger-500: var(--color-error-main);
  --onboard-danger-600: var(--color-error-main);
  --onboard-danger-700: var(--color-error-dark);

  --onboard-warning-100: var(--color-error-background);
  --onboard-warning-400: var(--color-error-light);
  --onboard-warning-500: var(--color-error-light);
  --onboard-warning-600: var(--color-error-main);
  --onboard-warning-700: var(--color-error-dark);

  /* Connect modal */
  --onboard-modal-z-index: 1301;

  --onboard-modal-backdrop: rgba(99, 102, 105, 0.75);

  --onboard-modal-border-radius: var(--w3o-border-radius);

  --onboard-connect-sidebar-progress-background: var(--color-border-main);

  --onboard-link-color: var(--color-primary-main);

  --onboard-wallet-app-icon-border-color: var(--color-border-light);
  --onboard-wallet-app-icon-background-transparent: rgba(255, 255, 255, 0.2);
  --onboard-wallet-app-icon-background-light-gray: rgba(255, 255, 255, 0.5);

  --onboard-wallet-button-border-radius: var(--w3o-border-radius);
  --onboard-wallet-button-background-hover: var(--color-background-light);

  /* Account select (modal) */

  --account-select-white: var(--onboard-white);
  --account-select-black: var(--onboard-black);

  --account-select-primary-100: var(--onboard-primary-100);
  --account-select-primary-200: var(--onboard-primary-200);
  --account-select-primary-300: var(--onboard-primary-300);
  --account-select-primary-500: var(--onboard-primary-500);
  --account-select-primary-600: var(--onboard-primary-600);

  --account-select-gray-100: var(--onboard-gray-100);
  --account-select-gray-200: var(--onboard-gray-200);
  --account-select-gray-300: var(--onboard-gray-300);
  --account-select-gray-500: var(--onboard-gray-500);
  --account-select-gray-700: var(--onboard-gray-700);

  --account-select-danger-500: var(--onboard-danger-500);

  --onboard-account-select-modal-z-index: 1301;
}

#walletconnect-qrcode-modal {
  padding: 20px !important;
}

#walletconnect-wrapper {
  color: #162d45;
}

#walletconnect-wrapper .walletconnect-modal__footer {
  flex-wrap: wrap;
  gap: 5px;
}

/* Keystone modal */
#kv_sdk_container + .ReactModalPortal > div {
  z-index: 1301 !important;
}
#kv_sdk_container + .ReactModalPortal .ReactModal__Content {
  padding: 0 !important;
}

.styles_container__sWsDn {
  height: var(--header-height);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  border-radius: 0 !important;
  background-color: var(--color-background-paper);
  border-bottom: 1px solid var(--color-border-light);
}

.styles_element___dnfW {
  height: 100%;
  border-right: 1px solid var(--color-border-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.styles_element___dnfW .MuiBadge-standard {
  font-size: 12px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}

[data-theme='dark'] .styles_element___dnfW .MuiBadge-standard {
  background-color: var(--color-primary-main);
}

.styles_menuButton__xLToS,
.styles_logo__gLb3i {
  flex: 1 1;
  border: none;
  align-items: flex-start;
}

.styles_logoMobile__I5_R2 {
  display: none;
}

.styles_logo__gLb3i img,
.styles_logo__gLb3i svg,
.styles_logoMobile__I5_R2 svg {
  width: auto;
  display: block;
  color: var(--color-logo-main);
  height: 20px;
}

.styles_logo__gLb3i {
  padding: var(--space-2);
}

.styles_menuButton__xLToS {
  display: none;
}

.styles_networkSelector__dMtwo {
  border-right: none;
}

.styles_connectWallet__Fn8aV {
  flex-shrink: 0;
}

@media (max-width: 899.95px) {
  .styles_logo__gLb3i {
    display: none;
  }

  .styles_logoMobile__I5_R2 {
    display: flex;
    flex: 1 1;
    border: none;
    align-items: flex-start;
    margin-left: var(--space-2);
  }

  .styles_menuButton__xLToS {
    display: flex;
    flex: 0 1;
  }
}

@media (max-width: 599.95px) {
  .styles_hideMobile__NXXje {
    display: none;
  }
}

.styles_connectedContainer__rsd1b {
  display: flex;
  align-items: center;
}

.styles_buttonContainer__Cqq8H {
  display: flex;
  align-items: center;
  text-align: left;
  gap: var(--space-1);
  padding: 0 var(--space-2);
}

.styles_popoverContainer__g9zko {
  padding: var(--space-2);
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
}

.styles_largeGap__i7hlp {
  gap: var(--space-2);
}

.styles_addressName__YceNU {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.styles_profileImg__mp2mF {
  border-radius: var(--space-2);
  width: 32px;
  height: 32px;
}

.styles_profileData__eo8wP {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.styles_address__85Q0i {
  height: 40px;
}

.styles_address__85Q0i div[title] {
  font-weight: bold;
}

.styles_rowContainer__5y393 {
  align-self: stretch;
  border: 1px solid var(--color-border-light);
  border-radius: 4px;
}

.styles_row___i7uy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--color-border-light);
  padding: 12px;
  margin-top: -2px;
}

.styles_row___i7uy:first-of-type {
  border: 0;
}

.styles_loginButton__2PS4Y {
  min-height: 42px;
}

.styles_loginError__ac973 {
  width: 100%;
  margin: 0;
}

@media (max-width: 599.95px) {
  .styles_notConnected__9cx_a {
    display: none;
  }
}

.styles_icon__bp_6x {
  width: auto;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
}

.styles_dialogActions__DpoTJ {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 599.95px) {
  .styles_dialogActions__DpoTJ {
    flex-direction: column;
    width: 100%;
  }
  .styles_dialogActions__DpoTJ > span {
    width: 100%;
  }
}

.styles_container__Y8ngK {
  display: flex;
  align-items: center;
  gap: 0.5em;
  line-height: 1.4;
  width: 100%;
}

.styles_avatarContainer__r3MOz {
  flex-shrink: 0;
  position: relative;
}

.styles_avatarContainer__r3MOz > * {
  width: 100% !important;
  height: 100% !important;
}

.styles_addressContainer__RU_yK {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.styles_inline__EL3Jf {
  display: flex;
  align-items: center;
}

.styles_container__wefzB {
  display: flex;
  align-items: center;
  gap: var(--space-1);
  justify-content: center;
}

.styles_imageContainer__LrMSJ {
  display: flex;
  justify-content: center;
  position: relative;
}

.styles_walletIcon__Q1bXk {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -6px;
  right: -6px;
  border-radius: 50%;
  border: 2px solid var(--color-background-paper);
  background-color: var(--color-background-main);
  overflow: hidden;
}

.styles_walletIcon__Q1bXk img {
  padding: 2px;
}

[data-theme='dark'] .styles_imageContainer__LrMSJ img[alt*='Ledger'] {
  filter: invert(100%);
}

@media (max-width: 599.95px) {
  .styles_buttonContainer__OIjaW button {
    font-size: 12px;
  }

  .styles_imageContainer__LrMSJ img {
    width: 22px;
    height: auto;
  }
}

@media (max-width: 899.95px) {
  .styles_walletDetails__5RxWv {
    display: none;
  }
}

.styles_container__bQpP1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
}

.styles_accountContainer__1Oa7I {
  width: 100%;
  margin-bottom: var(--space-1);
}

.styles_accountContainer__1Oa7I > span {
  border-radius: 8px 8px 0 0;
}

.styles_addressContainer__edfLO {
  border-radius: 0 0 8px 8px;
  padding: 12px;
  border: 1px solid var(--color-border-light);
  border-top: 0;
  font-size: 14px;
}

.styles_warningButton__ERboJ {
  background-color: var(--color-warning-background);
  color: var(--color-warning-main);
  font-size: 12px;
}

.styles_warningButton__ERboJ.MuiButton-root:hover {
  background-color: var(--color-warning-background);
}

.styles_address__jyeG2 {
  height: 40px;
}

.styles_address__jyeG2 div[title] {
  font-weight: bold;
}

.styles_rowContainer__eSWjg {
  align-self: stretch;
  border: 1px solid var(--color-border-light);
  border-radius: 4px;
}

.styles_row__wmiXr {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--color-border-light);
  padding: 12px;
  margin-top: -2px;
}

.styles_row__wmiXr:first-of-type {
  border: 0;
}

.styles_indicator__qjz6Z {
  display: flex;
  align-items: center;
  min-width: 70px;
  font-size: 12px;
  justify-content: center;
}

.styles_inlineIndicator__YwBwy {
  display: inline-block;
  min-width: 70px;
  font-size: 11px;
  line-height: normal;
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
}

.styles_withLogo__FWgZY {
  display: flex;
  align-items: center;
  gap: var(--space-1);
  padding: 0;
  min-width: 115px;
  font-size: 14px;
  justify-content: flex-start;
}

.styles_onlyLogo__dWFUa {
  min-width: 0;
}

@media (max-width: 899.95px) {
  .styles_indicator__qjz6Z {
    min-width: 35px;
  }
  .styles_responsive__V36CQ {
    min-width: 0;
  }
  .styles_responsive__V36CQ .styles_name__7_pYH {
    display: none;
  }
}

@container my-accounts-container (max-width: 500px) {
  .styles_responsive__V36CQ {
    min-width: 0;
  }
  .styles_responsive__V36CQ .styles_name__7_pYH {
    display: none;
  }
}

.styles_select__6X7_m {
  height: 100%;
}

.styles_select__6X7_m:after,
.styles_select__6X7_m:before {
  display: none;
}

.styles_select__6X7_m *:focus-visible {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

.styles_select__6X7_m .MuiSelect-select {
  padding-right: 40px !important;
  padding-left: 16px;
  height: 100%;
  display: flex;
  align-items: center;
}

.styles_select__6X7_m .MuiSelect-icon {
  margin-right: var(--space-2);
}

.styles_select__6X7_m .Mui-disabled {
  pointer-events: none;
}

.styles_select__6X7_m .MuiMenuItem-root {
  padding: 0;
}

.styles_listSubHeader__f4uey {
  background-color: var(--color-background-main);
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  letter-spacing: 1px;
  width: 100%;
  margin-top: var(--space-1);
}

[data-theme='dark'] .styles_undeployedNetworksHeader__5jm9N {
  background-color: var(--color-secondary-background);
}

.styles_plusIcon__SfGAl {
  background-color: var(--color-background-main);
  color: var(--color-border-main);
  border-radius: 100%;
  height: 20px;
  width: 20px;
  padding: 4px;
  margin-left: auto;
}

.styles_newChip__ijTaU {
  font-weight: bold;
  letter-spacing: -0.1px;
  margin-top: -18px;
  margin-left: -14px;
  transform: scale(0.7);
}

.styles_item__M09AF {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-1);
  width: 100%;
}

.styles_multiChainChip__XspgY {
  padding: var(--space-2) 0;
  margin: 2px;
  border-color: var(--color-border-main);
}

.styles_comingSoon__kN2bI {
  background-color: var(--color-border-light);
  border-radius: 4px;
  color: var(--color-text-primary);
  padding: 4px 8px;
}

.styles_dialog__1YM_c .MuiDialogActions-root {
  border-top: 1px solid var(--color-border-light);
  padding: var(--space-2) var(--space-3);
}

.styles_dialog__1YM_c .MuiDialogActions-root > :last-of-type:not(:first-of-type) {
  order: 2;
}

.styles_dialog__1YM_c .MuiDialogActions-root:after {
  content: '';
  order: 1;
  flex: 1 1;
}

.styles_dialog__1YM_c .MuiDialogTitle-root {
  border-bottom: 1px solid var(--color-border-light);
}

@media (min-width: 600px) {
  .styles_dialog__1YM_c .MuiDialog-paper {
    min-width: 600px;
    margin: 0;
  }
}

.styles_select__0l3zQ {
  height: 100%;
}

.styles_select__0l3zQ:after,
.styles_select__0l3zQ:before {
  display: none;
}

.styles_select__0l3zQ *:focus-visible {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

.styles_select__0l3zQ .MuiSelect-select {
  padding-right: 40px !important;
  padding-left: 16px;
  height: 100%;
  display: flex;
  align-items: center;
}

.styles_select__0l3zQ .MuiSelect-icon {
  margin-right: var(--space-2);
}

.styles_select__0l3zQ .Mui-disabled {
  pointer-events: none;
}

.styles_select__0l3zQ .MuiMenuItem-root {
  padding: 0;
}

.styles_listSubHeader__1J7ey {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  line-height: 32px;
}

.styles_newChip__6UGYp {
  font-weight: bold;
  letter-spacing: -0.1px;
  margin-top: -18px;
  margin-left: -14px;
  transform: scale(0.7);
}

.styles_item__yy779 {
  display: flex;
  align-items: center;
  gap: var(--space-1);
}

.styles_disabledChip__IR7DD {
  background-color: var(--color-border-light);
  border-radius: 4px;
  color: var(--color-text-primary);
  padding: 4px 8px;
  margin-left: auto;
}

.styles_container__dNnqe {
  padding: var(--space-2);
  margin: var(--space-2) 0;
  border-radius: 4px;
}

.styles_container__dNnqe.styles_error__s_zwE {
  background-color: var(--color-error-background);
  color: var(--color-error-dark);
}

.styles_container__dNnqe.styles_warning__YdPx6 {
  background-color: var(--color-warning-background);
}

.styles_container__dNnqe.styles_info__tDwUU {
  background-color: var(--color-info-background);
  color: var(--color-primary-main);
}

.styles_container__dNnqe.styles_info__tDwUU svg {
  color: var(--color-text-secondary);
}

.styles_message__jVdC3 {
  display: flex;
  align-items: flex-start;
  gap: var(--space-1);
}

.styles_message__jVdC3 button {
  vertical-align: baseline;
  text-decoration: underline;
}

.styles_details__CenK1 {
  margin-top: var(--space-1);
  color: var(--color-primary-light);
  word-break: break-word;
}

.styles_radioContainer__Di6cQ {
  border: 1px solid var(--color-border-light);
  margin: 0;
  border-radius: 6px;
  height: 72px;
  flex-basis: 72px;
  padding: 0 var(--space-1);
}

.styles_radioGroup__XAtCk {
  gap: var(--space-2);
  flex-wrap: wrap;
}

.styles_active__h1mV1 {
  outline: 1px solid var(--color-primary-main);
  border-color: var(--color-primary-main);
}

.styles_active__h1mV1 .styles_radioTitle__2eEOe {
  font-weight: bold;
}

.styles_listItem__pR3FH {
  min-width: 0;
  margin-right: var(--space-1);
  color: var(--color-primary-main);
}

.styles_active__h1mV1 .styles_radioSubtitle__LDTNK {
  color: var(--color-text-primary);
}

.styles_statusButton__ctdTe {
  border-radius: 4px;
  padding: 6px;
  width: 32px;
  height: 32px;
  background: var(--color-warning-background);
  justify-self: flex-end;
  margin-left: auto;
}

.styles_statusButton__ctdTe.styles_processing__0_oq3 {
  background: var(--color-info-background);
}

@media (max-width: 400px) {
  .styles_radioGroup__XAtCk {
    flex-direction: column;
  }
}

.styles_container__r2suB {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-1);
  height: 100%;
  justify-content: center;
  margin: 0 var(--space-2);
}

.styles_tokenButton__weiXf {
  display: flex;
  border-radius: 6px;
  padding: 0px var(--space-1) 0px var(--space-1);
  background-color: var(--color-border-background);
  margin: var(--space-1);
  height: 30px;
  align-items: center;
  justify-content: center;
  gap: var(--space-1);
  margin-left: 0;
  margin-right: 0;
  align-self: stretch;
}

.styles_sep5__a33rB {
  height: 42px;
}

[data-theme='dark'] .styles_allocationBadge__5a1pr .MuiBadge-dot {
  background-color: var(--color-primary-main);
}

.styles_redeemButton__lEPPc {
  margin-left: var(--space-1);
  padding: calc(var(--space-1) / 2) var(--space-1);
}

.styles_container__LSA9i {
  position: fixed;
  top: var(--header-height);
  right: 0;
  z-index: 2000;
}

.styles_row__JNLvf {
  max-width: 400px;
  display: flex;
  justify-content: flex-end;
  word-break: break-word;
}

.styles_link__AGgWz {
  text-decoration: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-top: 0.3em;
}

.styles_container__LSA9i details {
  margin-bottom: var(--space-1);
  max-height: 200px;
  overflow: auto;
}

.styles_container__LSA9i pre {
  margin: var(--space-1) 0 var(--space-2);
  white-space: pre-wrap;
  color: var(--color-primary-light);
}

.styles_container__LSA9i summary {
  text-decoration: underline;
  cursor: pointer;
  list-style: none;
  margin-top: 4px;
}

.styles_container__LSA9i summary::-webkit-details-marker {
  display: none;
}

.styles_item__00jp1 {
  position: relative;
  padding: 8px 24px;
}

.styles_item__00jp1:not(:last-of-type):after {
  content: '';
  background: var(--color-border-background);
  position: absolute;
  bottom: 0;
  left: 24px;
  height: 2px;
  width: calc(100% - 48px);
}

.styles_requiresAction__7u3rS {
  background-color: var(--color-primary-background);
}

.styles_avatar__MBgc8 {
  min-width: 42px;
}

.styles_secondaryText__vJcHa {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-border-main);
}

.styles_wrapper__54N_g {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 156px;
  color: var(--color-border-main);
}

.styles_scrollContainer__cEDIu {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
}

.styles_bell__IPWHT {
  display: flex;
  justify-content: center;
  padding: var(--space-2) var(--space-2);
}

.styles_bell__IPWHT svg path {
  stroke: var(--color-text-primary);
}

.styles_popoverContainer__ut7Hf {
  width: 446px;
  border: 1px solid var(--color-border-light);
}

@media (max-width: 599.95px) {
  .styles_popoverContainer__ut7Hf {
    width: calc(100vw - 30px);
  }
}

.styles_popoverHeader__WaXEj {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-3);
  border-bottom: 2px solid var(--color-background-main);
}

.styles_popoverFooter__brAga {
  padding: var(--space-2) var(--space-3);
  display: flex;
  align-items: center;
}

.styles_expandButton__1DZ1d {
  box-sizing: border-box;
  background-color: var(--color-border-light);
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 18px;
  padding: 0;
}

.styles_expandButton__1DZ1d > * {
  color: var(--color-border-main);
}

.styles_unreadCount__C1XlJ {
  display: inline-block;
  background: var(--color-secondary-light);
  border-radius: 6px;
  margin-left: 9px;
  color: var(--color-static-main);
  text-align: center;
  width: 18px;
  height: 18px;
}

.styles_settingsLink___JJYL {
  margin-left: auto;
  display: flex;
  align-items: center;
  text-decoration: unset;
  gap: var(--space-1);
}

.styles_header__WCnOa {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1201;
  background: var(--color-background-paper);
  padding-top: env(safe-area-inset-top);
}

.styles_main__ml_aX {
  background-color: var(--color-background-main);
  padding-left: 230px;
  padding-top: calc(var(--header-height) + env(safe-area-inset-top));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.styles_sticky__Ko7Tk {
  position: -webkit-sticky;
  position: sticky;
  top: calc(var(--header-height) + env(safe-area-inset-top));
  z-index: 1200;
}

.styles_mainAnimated__ZCSNt {
  transition: padding 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.styles_mainNoSidebar__9ybQQ {
  padding-left: 0;
}

.styles_content__TMlnS {
  flex: 1 1;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.styles_content__TMlnS main {
  padding: var(--space-3);
}

.styles_sidebarTogglePosition__fzyvh {
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  /* mimics MUI drawer animation */
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.styles_sidebarTogglePosition__fzyvh.styles_sidebarOpen__3KHqw {
  transform: translateX(230px);
}

.styles_sidebarToggle__QFUUE {
  height: 100vh;
  width: var(--space-1);
  background-color: var(--color-border-light);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
}

.styles_sidebarToggle__QFUUE button {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -3px;
  transform: translateY(-50%);
  background-color: var(--color-border-light);
  -webkit-clip-path: inset(0 -14px 0 0);
          clip-path: inset(0 -14px 0 0);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.styles_sidebarToggle__QFUUE:hover,
.styles_sidebarToggle__QFUUE:hover button {
  background-color: var(--color-background-light);
}

@media (max-width: 899.95px) {
  .styles_main__ml_aX {
    padding-left: 0;
  }

  .styles_smDrawerHidden__k5ACE {
    display: none;
  }
}

@media (max-width: 599.95px) {
  .styles_main__ml_aX main {
    padding: var(--space-2);
  }
}

.styles_container__k319H {
  padding: 5vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1 1;
}

.styles_container__FdxfA {
  padding: var(--space-2);
  font-size: 13px;
}

.styles_container__FdxfA ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
  row-gap: 0.2em;
  column-gap: var(--space-2);
  align-items: center;
}

.styles_container__FdxfA li {
  padding: 0;
  margin: 0;
}

.styles_container__FdxfA li:not(:last-of-type):after {
  content: '|';
  margin-left: var(--space-2);
}

.styles_container__FdxfA li a:not([href]) {
  text-decoration: none;
  pointer-events: none;
}

@media (max-width: 599.95px) {
  .styles_container__FdxfA li:not(:last-of-type):after {
    visibility: hidden;
  }
}

.styles_container__E8CeT {
  display: inline-flex;
  align-items: center;
  gap: var(--space-1);
  color: var(--color-text-primary);
}

.styles_verticalAlign__pkWnF {
  vertical-align: middle;
}

.styles_image__bi1_f {
  display: block;
  width: auto;
}

[data-theme='dark'] .styles_image__bi1_f {
  background: var(--color-secondary-main);
  border-radius: 4px;
  padding: 2px;
}

.styles_container__fG8A8 {
  position: relative;
}

.styles_threshold__uQlXV {
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 2;
  border-radius: 100%;
  font-size: 12px;
  min-width: 24px;
  min-height: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 16px;
  font-weight: 700;
  background-color: var(--color-secondary-light);
}

.styles_dialog__GH0VA {
  top: 52px;
  left: 230px;
  z-index: 3;
  transition: left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding-top: 60px; /* Remove once temporary banner is removed */
}

.styles_dialog__GH0VA.styles_fullWidth__EV_zx {
  left: 0;
}

.styles_dialog__GH0VA .MuiDialogActions-root {
  border-top: 2px solid var(--color-border-light);
  padding: var(--space-3);
}

.styles_dialog__GH0VA .MuiDialogActions-root > :last-of-type:not(:first-of-type) {
  order: 2;
}

.styles_dialog__GH0VA .MuiDialogActions-root:after {
  content: '';
  order: 1;
  flex: 1 1;
}

.styles_title__ibzpF {
  display: flex;
  align-items: center;
  padding: 0;
}

.styles_buttons__QjZ_m {
  margin-left: auto;
  padding: var(--space-1);
}

.styles_close__ZRBk5 {
  color: var(--color-border-main);
  padding: var(--space-1);
  background-color: var(--color-border-light);
}

.styles_paper__VHI4v {
  padding-bottom: var(--space-8);
  background-color: var(--color-border-background);
}

@media (min-width: 600px) {
  .styles_dialog__GH0VA .MuiDialog-paper {
    min-width: 600px;
    margin: 0;
  }
}

@media (min-width: 900px) {
  .styles_title__ibzpF {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

@media (max-width: 899.95px) {
  .styles_dialog__GH0VA {
    left: 0;
    top: 0;
    z-index: 1300;
  }

  .styles_dialog__GH0VA .MuiDialogActions-root {
    padding: 0;
  }

  .styles_title__ibzpF {
    margin-bottom: var(--space-3);
    background-color: var(--color-background-paper);
  }

  .styles_close__ZRBk5 {
    background-color: unset;
  }

  .styles_close__ZRBk5 svg {
    font-size: 1.5rem;
  }
}

.styles_container__SUr_1 {
  padding: var(--space-2) var(--space-1);
}

.styles_info__oeUkP {
  padding: 0 var(--space-1);
}

.styles_safe__NJmuY {
  display: flex;
  gap: 12px;
  text-align: left;
  align-items: center;
}

.styles_iconButtons__oL_Ca {
  margin-top: 10px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.styles_iconButton__IJMfB {
  border-radius: 4px;
  padding: 6px;
  color: var(--color-primary-main);
  background-color: var(--color-background-main);
  width: 32px;
  height: 32px;
}

.styles_iconButton__IJMfB:hover {
  background-color: var(--color-secondary-background);
}

.styles_address__u3Hst {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

.styles_button__o1QSD {
  border-radius: 4px;
  padding: 6px;
  width: 32px;
  height: 32px;
  background: var(--color-warning-background);
}

.styles_list__0_LrF {
  display: grid;
  grid-gap: 4px;
  gap: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.styles_icon__JvcDe {
  min-width: 0;
  margin-right: var(--space-2);
}

.styles_listItemButton__c1vL_ {
  border-radius: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.styles_list__0_LrF .MuiListItemButton-root {
  color: var(--color-text-primary);
}

[data-theme='dark'] .styles_list__0_LrF .Mui-selected {
  background-color: var(--color-border-light);
}

.styles_list__0_LrF .MuiListItemButton-root:hover {
  border-radius: 6px;
  background-color: var(--color-background-light);
}

.styles_list__0_LrF .Mui-selected {
  border-radius: 6px;
  background-color: var(--color-background-main);
}

.styles_listItemButton__c1vL_ .beamer_icon.active {
  top: auto;
  left: 28px;
  bottom: 10px;
  width: 6px;
  height: 6px;
  color: transparent;
}

.styles_container__Vy4i0 {
  height: 100vh;
  padding-top: var(--header-height);
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: var(--color-background-paper);
}

.styles_container__Vy4i0 {
  width: 230px;
}

.styles_scroll__rniQx {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.styles_drawer__5p8Zn {
  width: 550px;
  max-width: 90vw;
  padding-top: var(--header-height);
  border-right: 1px solid var(--color-border-light);
  overflow-y: auto;
  height: 100%;
}

.styles_dataWidget__XM2MW {
  margin-top: var(--space-4);
  border-top: 1px solid var(--color-border-light);
}

.styles_noSafeHeader__i_hn2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 100px;
}

.styles_drawerButton__umTFL {
  position: absolute !important;
  z-index: 2;
  color: var(--color-text-primary);
  padding: 8px 0;
  right: 0;
  transform: translateX(50%);
  margin-top: 54px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 0;
  cursor: pointer;
  background-color: var(--color-background-main);
}

.styles_drawerButton__umTFL:hover {
  background-color: var(--color-secondary-background);
}

.styles_drawerButton__umTFL svg {
  transform: translateX(-25%);
}

@media (max-width: 899.95px) {
  .styles_container__Vy4i0 {
    padding-top: var(--header-height);
    border-right: 1px solid var(--color-border-light);
  }

  .styles_drawer__5p8Zn {
    max-width: 90vw;
  }

  .styles_drawerButton__umTFL {
    width: 60px;
    height: 60px;
    margin-top: 44px;
  }
}

.styles_menu__9WbmE .MuiPaper-root {
  border-radius: 8px !important;
}

.styles_menu__9WbmE .MuiList-root {
  padding: 4px;
}

.styles_menu__9WbmE .MuiMenuItem-root {
  padding-left: 12px;
  min-height: 40px;
  border-radius: 8px !important;
}

.styles_menu__9WbmE .MuiMenuItem-root:hover {
  background-color: var(--color-secondary-background);
}

.styles_menu__9WbmE .MuiListItemIcon-root {
  min-width: 26px;
}

.styles_container__fGSoa {
  container-type: inline-size;
  container-name: my-accounts-container;
  display: flex;
  justify-content: center;
}

.styles_myAccounts__CnlH8 {
  width: 100vw;
  max-width: 750px;
  margin: var(--space-2);
}

.styles_sidebarAccounts__XTA6h {
  margin: 0 !important;
}

.styles_safeList__FIGFt {
  padding: var(--space-2) var(--space-2) var(--space-3);
  margin-bottom: var(--space-1);
}

.styles_header__kEyV0 {
  display: flex;
  justify-content: space-between;
  padding: var(--space-3) 0;
  gap: var(--space-2);
}

.styles_sidebarHeader__T9Syp {
  padding: var(--space-3) var(--space-2);
  border-bottom: 1px solid var(--color-border-light);
}

.styles_sidebarHeader__T9Syp > h1 {
  font-size: 24px;
}

.styles_headerButtons__QmFEv {
  display: flex;
  flex-direction: row;
  gap: var(--space-1);
}

.styles_noPinnedSafesMessage__VDkiB {
  display: flex;
  justify-content: center;
  border: 1px solid var(--color-border-light);
  padding: var(--space-3);
  border-radius: var(--space-1);
  border-style: dashed;
}

.styles_listHeader__LqKgm {
  display: flex;
}

.styles_listHeader__LqKgm svg path {
  stroke: var(--color-text-primary);
}

@media (max-width: 899.95px) {
  .styles_container__fGSoa {
    width: auto;
  }
}

.styles_safeList__FIGFt .MuiAccordionSummary-root {
  background: var(--color-background-paper) !important;
  padding-left: 0;
  min-height: 0;
  justify-content: left;
  vertical-align: middle;
}

.styles_search__rezal .MuiInputBase-root {
  border: 1px solid transparent !important;
}

@media (max-width: 599.95px) {
  .styles_header__kEyV0 {
    flex-direction: column;
  }

  .styles_headerButtons__QmFEv > span {
    flex: 1 1;
  }

  .styles_headerButtons__QmFEv > span > a {
    width: 100%;
  }
}

.styles_listItem__4kW2Z {
  border: 1px solid var(--color-border-light);
  border-radius: var(--space-1);
  margin-bottom: 12px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  flex-wrap: wrap;
}

.styles_currentListItem__VUiIo {
  border: 1px solid var(--color-secondary-light);
  border-left-width: 6px;
  background-color: var(--color-background-light) !important;
}

.styles_currentListItem__VUiIo.styles_multiListItem__d88vk {
  border: 1px solid var(--color-border-light);
  background-color: none;
}

.styles_listItem__4kW2Z .MuiAccordion-root,
.styles_listItem__4kW2Z .MuiAccordion-root:hover > .MuiAccordionSummary-root {
  background-color: transparent;
}

.styles_listItem__4kW2Z .MuiAccordion-root.Mui-expanded {
  background-color: var(--color-background-paper);
}

.styles_listItem__4kW2Z.styles_subItem__rwxxb {
  margin-bottom: 8px;
}

.styles_subItem__rwxxb .styles_borderLeft__Ri5o8 {
  top: 0;
  bottom: 0;
  position: absolute;
  border-radius: 6px;
  border: 1px solid var(--color-border-light);
}

.styles_subItem__rwxxb.styles_currentListItem__VUiIo .styles_borderLeft__Ri5o8 {
  border-left: 4px solid var(--color-secondary-light);
}

.styles_listItem__4kW2Z > :first-child {
  flex: 1 1;
  width: 90%;
}

.styles_safeLink__gsM_e {
  display: grid;
  padding: var(--space-2) var(--space-1) var(--space-2) var(--space-2);
  grid-template-columns: 60px 10fr 2fr 3fr;
  align-items: center;
}

.styles_multiSafeLink__ZbOg0 {
  grid-template-columns: 60px 8fr 5fr 3fr;
}

.styles_safeSubLink__aR0M0 {
  grid-template-columns: 60px 3fr minmax(auto, 2fr);
}

.styles_safeName__d5TDf,
.styles_safeAddress__S4K2I {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.styles_listHeader__xYWlK {
  display: flex;
}

.styles_listHeader__xYWlK svg path {
  stroke: var(--color-text-primary);
}

.styles_multiChains__sUSpU {
  display: flex;
  justify-content: flex-end;
}

.styles_multiChains__sUSpU > span {
  margin-left: -5px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  outline: 2px solid var(--color-background-paper);
}

.styles_chainIndicator__i5UG2 {
  justify-content: flex-end;
}

.styles_chipSection__C3nxE {
  width: 100%;
}

.styles_chipSection__C3nxE:empty {
  display: none;
}

@media (max-width: 899.95px) {
  .styles_safeLink__gsM_e {
    padding-right: 0;
  }
}

@media (max-width: 599.95px) {
  .styles_safeLink__gsM_e {
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
      'a b d'
      'a c d';
  }

  .styles_safeLink__gsM_e :nth-child(1) {
    grid-area: a;
  }
  .styles_safeLink__gsM_e :nth-child(2) {
    grid-area: b;
  }
  .styles_safeLink__gsM_e :nth-child(3) {
    grid-area: c;
    text-align: left;
  }
  .styles_safeLink__gsM_e :nth-child(4) {
    grid-area: d;
  }

  .styles_multiChains__sUSpU {
    justify-content: flex-start;
  }

  .styles_chainIndicator__i5UG2 {
    justify-content: flex-start;
  }
}

.styles_input__T5zR5 {
  width: calc(100% - 40px);
}

.styles_input__T5zR5 .MuiInputBase-input {
  padding: var(--space-1) var(--space-2);
}

.styles_input__T5zR5 input[type='text'] {
  padding-left: 0;
  padding-right: 0;
}

.styles_input__T5zR5 [title] {
  font-weight: bold;
  color: var(--color-text-primary);
}

.styles_value__4Bgws {
  width: 100%;
}

.styles_wrapper__mIMPn .MuiInputLabel-root.Mui-error[data-shrink='false'] {
  padding: 5px 4px;
}

.styles_wrapper__mIMPn .MuiInputAdornment-root {
  margin-left: 0;
}

.styles_openButton__FKdki svg {
  transition: transform 0.3s ease-in-out;
}

.styles_rotated__0Kjra svg {
  transform: rotate(180deg);
}

.styles_readOnly__kjH4h .MuiInputBase-input {
  visibility: hidden;
}

/* TODO: Apply these styles in the MUI theme once its part of this repository */
.inputs_input__zUk_k .MuiFormHelperText-root {
  position: absolute;
  bottom: -20px;
}

.inputs_input__zUk_k .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
  transform: translate(16px, 22px) scale(1);
}

.inputs_input__zUk_k .MuiInputBase-root {
  background-color: var(--color-background-paper);
  border-radius: 6px;
  height: 66px;
  padding: 12px var(--space-2);
}

.inputs_input__zUk_k input {
  padding: 0;
}

.inputs_input__zUk_k .MuiInputBase-root fieldset {
  border-width: 1px !important;
}

.inputs_input__zUk_k .MuiInputBase-root:not(.Mui-error) fieldset {
  border-color: var(--color-border-light) !important;
}

@media (max-width: 899.95px) {
  .inputs_input__zUk_k .MuiFormHelperText-root {
    position: relative;
    bottom: 0;
  }
}

.styles_chip__xKVHP {
  border-radius: var(--space-2);
  padding-left: 4px;
  padding-right: 4px;
}

.styles_visibilityIcon__xafhO {
  font-size: 16px !important;
  color: var(--color-border-main) !important;
}

.styles_pendingLoopIcon__1MWiu {
  color: var(--color-info-dark) !important;
}

@media (max-width: 600px) {
  .styles_chip__xKVHP {
    margin: 0 var(--space-2) var(--space-2) var(--space-2);
  }
}

.styles_queueButton___Pf9b {
  display: flex;
  gap: var(--space-1);
  align-items: center;
  padding: 0;
  border: 0;
  cursor: pointer;
  position: relative;
  z-index: 1;
  background: transparent;
}

.styles_isMobile__9MhPp {
  padding: 0 var(--space-2) var(--space-2);
}

.styles_networks__kAPOk {
  display: flex;
  flex-wrap: wrap;
  margin-left: 6px;
  row-gap: 4px;
}

.styles_networks__kAPOk img {
  margin-left: -6px;
  outline: 2px solid var(--color-background-paper);
  border-radius: 50%;
}

.styles_moreChainsIndicator__sdo1C {
  margin-left: -5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--color-border-light);
  outline: 2px solid var(--color-background-paper);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.styles_dropbox__NJB4p {
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: var(--space-3) var(--space-5);
  margin: var(--space-3) 0;
  background: var(--color-secondary-background);
  color: var(--color-primary-light);
  transition:
    border 0.5s,
    background 0.5s;
}

.styles_verticalLine__EjaeN {
  display: flex;
  height: 18px;
  border-right: 1px solid var(--color-primary-main);
  margin-left: 7px;
  margin-top: -8px;
}

.styles_card__lVPio {
  width: 100%;
  border: 1px solid var(--color-border-light);
  margin: var(--space-2) 0;
}

.styles_fileIcon__7lUN_ {
  display: flex;
  align-items: center;
  padding: var(--space-1);
  border: 1px solid var(--color-text-primary);
}

.styles_exportIcon__Qv7S7 {
  min-width: unset;
  padding: var(--space-1);
}

.styles_header__NNVsi {
  border-bottom: 1px solid var(--color-border-light);
}

.styles_header__NNVsi .MuiCardHeader-avatar {
  margin-right: var(--space-2);
}

.styles_header__NNVsi .MuiCardHeader-action {
  align-self: center;
  margin: 0;
}

.styles_content__CLFPL {
  padding: var(--space-3);
}

.styles_listIcon__8DE1y {
  min-width: unset;
  margin-right: var(--space-3);
  padding-top: var(--space-1);
  align-self: flex-start;
}

.styles_networkIcon__By6S2 {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: calc(var(--space-1) / 2);
}

.styles_cardHeader__7_Rpv {
  text-align: center;
}

.styles_card__csnQK {
  margin: auto;
  padding: var(--space-3);
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

.styles_card__csnQK .MuiCardHeader-root,
.styles_card__csnQK .MuiCardContent-root {
  padding: 0;
}

.styles_infoIcon__iP94H {
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  margin: 4px;
  color: var(--color-text-secondary);
}

.styles_aside__GqrAa {
  margin-top: var(--header-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 700px;
  max-width: 100vw;
  padding-bottom: var(--space-3);
}

.styles_aside__GqrAa h4 {
  width: 100%;
  padding: var(--space-3) var(--space-3) 0;
  margin: 0;
}

.styles_aside__GqrAa hr {
  width: 100%;
  margin: var(--space-3) 0;
}

.styles_txs__7iNm7 {
  width: 100%;
}

.styles_txs__7iNm7 ul {
  padding: 0 var(--space-3) var(--space-2);
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
  list-style: none;
}

.styles_txs__7iNm7 li {
  margin: 0;
  padding: 0;
}

.styles_separator__PFMuH {
  border-left: 1px solid var(--color-border-light);
  height: calc(100% + 31px);
}

.styles_confirmButton__kAMrN {
  margin-top: var(--space-1);
}

.styles_txs__7iNm7 svg {
  color: var(--color-border-main);
  transition: color 0.1s ease-in;
  transform: scale(1.2);
}

.styles_txs__7iNm7 button:hover svg {
  color: var(--color-primary);
}

.styles_number__07D4D {
  background-color: var(--color-border-light);
  border-radius: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-top: var(--space-2);
}

.styles_close__3KapD {
  position: absolute;
  right: var(--space-2);
  top: var(--space-2);
  z-index: 1;
  padding: var(--space-1);
  color: var(--color-border-main);
}

.styles_details__LUPGv {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  padding: var(--space-2);
  margin: calc(-1 * var(--space-2));
  border-top: 1px solid var(--color-secondary-light);
}

.styles_dragHandle__4Cn_U {
  cursor: -webkit-grab;
  cursor: grab;
}

.styles_dragHandle__4Cn_U:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.styles_accordion__VulIv {
  opacity: 1 !important;
}

.styles_accordion__VulIv .MuiAccordionSummary-content {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 12px 0px;
}

.styles_txType__fAzvk {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: var(--space-1);
  color: var(--color-text-primary);
  overflow: hidden;
}

.styles_txTypeText__LLluX {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.styles_txInfo__eFw8y {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.styles_container__6E2fW {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.styles_container__6E2fW:last-of-type {
  margin-bottom: 0;
}

.styles_alert__Pyfu8 {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 10px;
  margin-bottom: 10px;
}

.styles_container__smoZ1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.styles_group__u3xSP {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.styles_inline__ohq2x {
  display: flex;
  align-items: center;
  gap: 8px;
}

.styles_icon__0_auY {
  height: 16px;
  width: 16px;
}

.styles_dot__zrX_v {
  height: 10px;
  width: 10px;
}

.styles_signers__2f3AR {
  padding: 0;
}

.styles_signers__2f3AR::before {
  content: '';
  position: absolute;
  border-left: 2px solid var(--color-border-light);
  left: 15px;
  top: 20px;
  height: calc(100% - 40px);
}

.styles_signers__2f3AR .MuiListItem-root:first-of-type {
  padding-top: 0;
}

.styles_signers__2f3AR .MuiListItem-root {
  padding-left: 0;
  padding-right: 0;
}

.styles_signers__2f3AR .MuiListItemIcon-root {
  color: var(--color-primary-main);
  justify-content: center;
  min-width: 32px;
  padding: var(--space-1) 0;
  background-color: var(--color-background-paper);
}

.styles_confirmationsTotal__IUzm4 {
  color: var(--color-border-main);
  display: inline;
  font-weight: normal;
}

.styles_listFooter__jEwEH {
  margin-left: var(--space-4);
}

.styles_encodedData__a0YR1 {
  line-break: anywhere;
  word-break: break-all;
}

.styles_buttonExpand__bXWOY {
  margin-top: 8px;
  padding: 0;
}

.styles_nestedWrapper__HLPd6 {
  padding-left: 12px;
}

.styles_summary__az4of {
  display: flex;
  gap: 8px;
  align-items: center;
}

/* TODO: Apply this style in the MUI theme once its part of this repository */

.accordion_accordion__NXUGI {
  min-height: 56px !important;
}

.styles_actionsHeader__23ye8 {
  border-bottom: 1px solid var(--color-border-light);
  cursor: auto !important;
  padding-left: var(--space-2);
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles_compactHeader__JJE7a {
  border: 0;
  padding-left: 0;
}

.styles_actionsHeader__23ye8 button {
  padding-left: 18px;
  padding-right: 18px;
}

.styles_divider__G8atk {
  margin-top: 14px;
  margin-bottom: 14px;
  border: 1px solid var(--color-border-light);
}

.styles_compact__ENEeY {
  display: flex;
  flex-direction: column;
}

.styles_compact__ENEeY > div:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.styles_compact__ENEeY > div ~ div {
  border-radius: 0;
  margin-top: -1px !important;
}

.styles_compact__ENEeY > div:hover,
.styles_compact__ENEeY > div.Mui-expanded {
  border-color: var(--color-border-light);
}

.styles_container___UQEK {
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.styles_details___YvqT {
  width: 66.6%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.styles_shareLink__3N_oh {
  display: flex;
  justify-content: flex-end;
  margin: var(--space-1);
  margin-bottom: -40px;
}

.styles_txNote__n_54z {
  margin: var(--space-1) 0;
  padding: 0 var(--space-2) var(--space-2);
  border-bottom: 1px solid var(--color-border-light);
}

.styles_txNote__n_54z:empty {
  display: none;
}

.styles_loading__OXPJK,
.styles_error__6eA8n,
.styles_txData__V4oPh,
.styles_txSummary__CFbSQ,
.styles_advancedDetails__GA8tV,
.styles_txModule__bxl1e {
  padding: var(--space-2);
}

.styles_txData__V4oPh {
  border-bottom: 1px solid var(--color-border-light);
}

.styles_txSummary__CFbSQ,
.styles_advancedDetails__GA8tV {
  height: 100%;
}

.styles_txSigners__Zdzmy {
  display: flex;
  width: 33.3%;
  flex-direction: column;
  padding: var(--space-3);
  border-left: 1px solid var(--color-border-light);
}

.styles_delegateCall__3LLbJ .styles_alert__jca6q {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 var(--space-1);
}

.styles_multiSend__Tvj41 {
  border-bottom: 1px solid var(--color-border-light);
}

.styles_buttons__uw7SJ {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--space-1);
  margin-top: var(--space-2);
}

.styles_buttons__uw7SJ > * {
  flex: 1 1;
}

.styles_buttons__uw7SJ button {
  width: 100%;
}

@media (max-width: 599.95px) {
  .styles_container___UQEK {
    flex-direction: column;
  }

  .styles_details___YvqT {
    width: 100%;
  }

  .styles_txSigners__Zdzmy {
    width: 100%;
    border-left: 0;
    border-top: 1px solid var(--color-border-light);
  }
}

.styles_cardContent__7s_bD {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
  padding: var(--space-3);
}

.styles_cardContent__7s_bD .errorMessage {
  margin: 0;
}

.styles_nestedDivider__G8Q98 {
  margin: 0 calc(-1 * var(--space-3));
}

.styles_form__hslHZ > .MuiFormControl-root {
  margin-bottom: 28px;
}

.styles_wrapper__VFRg7 {
  border: 1px solid var(--color-border-light);
  margin-top: var(--space-2);
}

.styles_accordion__N6xc_ {
  border: unset;
}

.styles_summary__NDCqm {
  background: unset !important;
}

.styles_header__fCdGU {
  font-weight: 700;
}

.styles_details__QitLn {
  padding-top: 0;
  padding-bottom: 12px;
}

.styles_copy__ZY_xn {
  padding: var(--space-2);
  padding-top: 4px;
}

.styles_icon__qA59i {
  margin-right: 4px;
}

.styles_button__Qksd4 {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}

.styles_alert__SZKY_ {
  padding: 0px 10px;
}

.styles_actionsHeader__SyoRv {
  border-bottom: 1px solid var(--color-border-light);
  cursor: auto !important;
  padding-left: var(--space-2);
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles_compactHeader__r_gjB {
  border: 0;
  padding-left: 0;
}

.styles_actionsHeader__SyoRv button {
  padding-left: 18px;
  padding-right: 18px;
}

.styles_divider__8a1i8 {
  margin-top: 14px;
  margin-bottom: 14px;
  border: 1px solid var(--color-border-light);
}

.styles_compact__4MqLr {
  display: flex;
  flex-direction: column;
}

.styles_compact__4MqLr > div:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.styles_compact__4MqLr > div ~ div {
  border-radius: 0;
  margin-top: -1px !important;
}

.styles_compact__4MqLr > div:hover,
.styles_compact__4MqLr > div.Mui-expanded {
  border-color: var(--color-border-light);
}

.styles_value__KB1c6 {
  display: flex;
  align-items: center;
  gap: var(--space-1);
}

.styles_gridRow__puGSG {
  display: grid;
  grid-template-columns: 25% auto;
  grid-gap: var(--space-1);
  gap: var(--space-1);
  justify-content: flex-start;
  max-width: 900px;
  overflow-x: auto;
  margin-top: 4px;
}

.styles_gridRow__puGSG:first-of-type {
  margin-bottom: 0;
}

.styles_gridEmptyRow__4ltpH {
  display: grid;
  grid-template-columns: 35% auto;
  grid-gap: var(--space-1);
  gap: var(--space-1);
  justify-content: flex-start;
  max-width: 900px;
  margin-top: var(--space-1);
  margin-bottom: var(--space-1);
  border-top: 1px solid var(--color-border-light);
}

.styles_title__YwLgF {
  color: var(--color-primary-light);
  font-weight: 400;
  word-break: break-all;
}

.styles_title__YwLgF span:nth-child(2) {
  word-break: normal;
}

.styles_gridRow__puGSG > * {
  flex-shrink: 0;
}

.styles_valueWrapper__uXPcr {
  min-width: 50%;
  flex-shrink: 0;
}

.styles_rawData__QU0Z0 {
  display: flex;
  align-items: center;
}

@media (max-width: 599.95px) {
  .styles_gridRow__puGSG {
    grid-template-columns: 1fr;
    gap: 0;
    margin-top: var(--space-1);
  }
}

.styles_popup__tYrT2 {
  position: fixed;
  z-index: 1300;
  bottom: var(--space-2);
  right: var(--space-2);
  max-width: 400px;
}

.styles_container__tNQ_n {
  padding: var(--space-2);
  border-radius: 0 !important;
}

.styles_container__tNQ_n label,
.styles_container__tNQ_n input {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

@media (max-width: 599.95px) {
  .styles_popup__tYrT2 {
    right: 0;
    bottom: 0;
  }
}

.styles_container__tNQ_n.styles_inverted__E71NI {
  background: var(--color-text-primary);
}

.styles_container__tNQ_n.styles_inverted__E71NI,
.styles_container__tNQ_n.styles_inverted__E71NI .MuiCheckbox-root,
.styles_container__tNQ_n.styles_inverted__E71NI a {
  color: var(--color-background-paper);
}

.styles_container__tNQ_n.styles_inverted__E71NI .Mui-checked {
  color: var(--color-background-paper);
}

.styles_container__tNQ_n.styles_inverted__E71NI .Mui-checked.Mui-disabled {
  opacity: 0.5;
}

.styles_container__2MT1o {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.styles_wrapper__4DX9U {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  text-align: center;
  padding: var(--space-2);
}

.styles_circle__AXZtM {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  background-color: var(--color-background-main);
}

.styles_badge__p_OGq .MuiBadge-badge {
  border: 2px solid var(--color-background-paper);
  border-radius: 50%;
  box-sizing: content-box;
}

